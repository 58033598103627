import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addEditDeliveryShape } from './../actions/deliveryShapes.actions';
import AddEditDeliveryShapeForm from './AddEditDeliveryShapeForm';
import { NavLink } from 'react-router-dom';
import { addErrorMessages } from './../../messages/messages.actions';
import { withRouter } from 'react-router-dom';
import { ADMIN_URL, API_TOKEN} from './../../../constants';
import Utils from '../../../utils/Utils';

// let md5 = require('md5');

class AddDeliveryShapePage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			name: '',
			priority: '',
			min_order_price: '',
			delivery_price: '',
			required_order_price_for_free_delivery: '',
			active_time_from: '',
			active_time_to: '',
			coords: []
		}

		// this.onChange = this.onChange.bind(this);
		// this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		// this.onSubmit = this.onSubmit.bind(this);
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeField = (fieldName, fieldValue) => {
		this.setState({ [fieldName]: fieldValue });
	}

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let coords = this.state.coords
        coords = coords.replace(" ", '');
        coords = coords.split('\n')

        console.log(coords)
        //|| coords[0]*1 != coords[coords.length - 1]
        if(coords.length < 3 ) {
			this.props.addErrorMessages(["Invalid coords"]);
            return;
        }

        let coordsToSend = [];
        coords.map(c => {

            let cc = c.split(",");
            coordsToSend.push({
                lat: cc[0]*1,
                lng: cc[1]*1,
                // placeId: Auth.getUserPlace()
            })
        })

		const post = {
			// deliveryShape_token: Utils.generateDeliveryShapeToken(this.state.deliveryShape_num), // md5(md5(this.state.deliveryShape_num + (this.state.currentDate))),
			name: this.state.name,
			priority: this.state.priority,
			min_order_price: this.state.min_order_price,
			delivery_price: this.state.delivery_price,
			required_order_price_for_free_delivery: this.state.required_order_price_for_free_delivery,
			active_time_from: this.state.active_time_from,
			active_time_to: this.state.active_time_to,
			coords: coordsToSend,
			active: this.state.active,
		}


		this.props.addEditDeliveryShape(post, this.props.history);
	}

	render() {
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings/category/6'}>{this.props.translations.sidemenu.orders}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "delivery-shapes"}>{this.props.translations.delivery_shapes.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.delivery_shapes.add}</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditDeliveryShapeForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeField={this.onChangeField}
										onChangeCheckbox={this.onChangeCheckbox}
										deliveryShape={this.state}
										actionText="Добави"
										isAdding="1"
										translations={this.props.translations}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = state => ({
	translations: state.lang,
	languages: state.languages.languages || {},
	currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',

});

// const mapDispatchToProps = (dispatch, ownProps) => {
// 	return {
// 		addDeliveryShape: (data, imageData) => dispatch(addDeliveryShape(ownProps.history, data, imageData)),
// 		addErrorMessages: (msgs) => dispatch(addErrorMessages(msgs))
// 	}
// }

export default withRouter(connect(mapStateToProps, { addEditDeliveryShape, addErrorMessages })(AddDeliveryShapePage));