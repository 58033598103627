import React, { Component } from 'react';
import Utils from '../../../utils/Utils';
import { API_URL } from './../../../constants';

const courierMapping = {
    "511": "ПЕТЪР",
    "512": "А. РАДОЕВ",
    "513": "ЕВА",
    "514": "В. ПЕТКОВ",
    "515": "ЛЮБО",
    "516": "Б. МИЛАДИНОВ",
    "517": "ДИАНА",
    "518": "ВЕСКО",
    "519": "НЕДИСЛАВ",
    "520": "МОХАМЕД",
    "521": "ИВО МИЛЧЕВ",
    "522": "Г. ВУЧКОВ",
    "523": "МИТКО",
    "524": "КАЛОЯН",
    "525": "А. ЧАЛЕВ",
    "526": "РАДОСТИН",
    "527": "РОБЕРТО",
    "528": "КОЦЕ",
    "529": "СПАС",
    "530": "ЯВОР",
    "531": "ПАНАЙОТ",
    "532": "ПЛ. ИВАНОВ",
    "533": "ПАВЕЛ",
    "534": "АНГЕЛ",
    "535": "НИКОЛ",
    "536": "М. ТОДОРОВ",
    "537": "К. ЧОБАНОВ",
    "538": "ЙОАН",
    "539": "ЛЪЧО",
    "540": "ЖОРО",
    "541": "К. ВЕЛИКОВ",
    "542": "ПЛАМЕН",
    "500": "ЦВЕТАН",
    "543": "Мартин Петров",
    "888": "Ресторант",
    "999": "ХХХХ",
};


class ListRegionsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_code: localStorage.getItem('tabl_courier_code') || '',
            courierName: '',
            orderData: null,
        };
    }

    componentDidMount() {
        this.setCourierName(this.state.search_code);
        this.fetchOrderData();
    }

    fetchOrderData = async () => {
        try {
            const url = window.location.href;
            const urlSegments = url.split('/');
            const orderId = urlSegments[urlSegments.length - 1];

            const response = await fetch(`${API_URL}orders/courier/${orderId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'admin': '1',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch order data');
            }

            const data = await response.json();
            this.setState({ orderData: data });
        } catch (error) {
            console.error('Error fetching order data:', error);
        }
    };

    handleTakeOrder = async () => {
        const { orderData, courierName } = this.state;

        if (!courierName) {
            alert('Моля въведете валиден 3-цифрен код преди да приемете поръчката');
            return;
        }

        try {
            const response = await fetch(`${API_URL}orders/attach_courier_into_order/${orderData.uuid}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'admin': '1',
                },
                body: JSON.stringify({ courierName }),
            });

            if (!response.ok) {
                throw new Error('Failed to update order with courier name');
            }

            const result = await response.json();
            alert(result.message || 'Поръчката е успешно приета от вас');
            this.fetchOrderData();
        } catch (error) {
            console.error('Error updating order:', error);
            alert('Възникна грешка при приемането на поръчката, моля опитайте пак');
        }
    };

    handleCodeChange = (e) => {
        const code = e.target.value;
        this.setState({ search_code: code });
        localStorage.setItem('tabl_courier_code', code);
        this.setCourierName(code);
    };

    setCourierName = (code) => {
        if (courierMapping[code]) {
            this.setState({ courierName: courierMapping[code] });
        } else {
            this.setState({ courierName: '' });
        }
    };

    render() {
        const { orderData, search_code, courierName } = this.state;

        let deliveryTime = orderData && orderData.delivery_time ? new Date(orderData.delivery_time) : new Date();
        deliveryTime = Utils.appendLeadingZeroes(deliveryTime.getDate()) + '.' +
            Utils.appendLeadingZeroes(deliveryTime.getMonth() + 1) + ' в ' +
            Utils.appendLeadingZeroes(deliveryTime.getHours()) + ':' +
            Utils.appendLeadingZeroes(deliveryTime.getMinutes());

        return (
            <main className="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">СИСТЕМА ЗА КУРИЕРИ - {courierName || "Не е избран куриер"}</li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    {/* <label htmlFor="courierCode">Вашият 3-цифрен код:</label> */}
                                                    <input
                                                        className="form-control"
                                                        id="courierCode"
                                                        type="text"
                                                        // placeholder="Въведете код"
                                                        name="search_code"
                                                        value={search_code}
                                                        onChange={this.handleCodeChange}
                                                    // maxLength="3"
                                                    />
                                                </div>
                                            </div>

                                            {orderData && courierName ? (
                                                <>
                                                    {orderData.courier ? (
                                                        <>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    Тази поръчка вече е асоциирана към куриер: {orderData.courier}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>

                                                        </>
                                                    )}
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <button className="btn btn-primary" onClick={this.handleTakeOrder}>
                                                                ВЗЕМИ ПОРЪЧКА
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <b><label>Поръчка N:</label></b>
                                                            <p>{orderData.id || '-'} / {orderData.place_order_number || '-'}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <b><label>Клиент:</label></b>
                                                            <p>{orderData.client_name || '-'}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <b><label>Адрес:</label></b>
                                                            <p>{orderData.client_address || '-'}</p>

                                                            <a
                                                                href={`https://www.google.com/maps/dir/?api=1&destination=${orderData.delivery_address_lat && orderData.delivery_address_lng
                                                                    ? `${orderData.delivery_address_lat},${orderData.delivery_address_lng}`
                                                                    : orderData.client_address}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                title="Google Карта с навигация до адреса"
                                                                style={{
                                                                    color: '#23282c',
                                                                    background: '#ccc',
                                                                    padding: 5,
                                                                    borderRadius: 5
                                                                }}
                                                            >
                                                                НАВИГАЦИЯ
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <b><label>Час на доставка:</label></b>
                                                            <p>{orderData.exact_time_selected ? deliveryTime : 'възможно най-скоро'}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <b><label>ОБЩО:</label></b>
                                                            <p>{orderData.total_price || '-'} лв.</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ) : (
                                                <div className="col-sm-12">
                                                    {courierName ? <p>Не е намерена поръчката.</p> : <p>Зареждане на поръчка... </p>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default ListRegionsPage;
