import React, { Component } from 'react'
import Utils from '../../utils/Utils';
import moment from 'moment';
import PAYMENT_STATUSES from './../common/enums/paymentStatuses';
import { PRODUCT_PRINT_GROUPS_NAME } from '../../constants';
import QRCode from 'qrcode.react';

export default class ComponentToPrint extends React.PureComponent {
    // prodPrintGroups = window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME) ? JSON.parse(window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME)) : [];
    render() {
        let orderDateFormat = this.props.order.ordertypeId == 2 ? new Date(this.props.order.delivery_time) : new Date(this.props.order.pickup_time);
        // orderDateFormat = Utils.appendLeadingZeroes(orderDateFormat.getDate()) + '.' + Utils.appendLeadingZeroes(parseInt(parseInt(orderDateFormat.getMonth()) + 1)) + ' ' + Utils.appendLeadingZeroes(orderDateFormat.getHours()) + ':' + Utils.appendLeadingZeroes(orderDateFormat.getMinutes());

        orderDateFormat = Utils.appendLeadingZeroes(orderDateFormat.getDate()) + '.' + Utils.appendLeadingZeroes(parseInt(parseInt(orderDateFormat.getMonth()) + 1)) + ' в ' + Utils.appendLeadingZeroes(orderDateFormat.getHours()) + ':' + Utils.appendLeadingZeroes(orderDateFormat.getMinutes()) +
            (
                Utils.getSettingsValue(this.props.settings, 'enable_showing_diapasone_in_times_for_delivery_or_pickup_in_order_modal') == 1 ?
                    ' - ' + this.props.addMinutesToTime(orderDateFormat.getHours() + ':' + orderDateFormat.getMinutes(), Utils.getSettingsValue(this.props.settings, 'minutes_margin_working_hours_choose_date_for_pickup_or_delivery_in_order_modal'))
                    :
                    ''
            )

        let totalSum = 0;
        if (this.props.order && this.props.order.total_price) {
            totalSum = this.props.order.total_price;
        }

        let delivery_price = 0;
        if (this.props.order && this.props.order.total_price) {
            delivery_price = this.props.order.delivery_price;
        }

        // let currentRegion = null;
        // if (this.props.order.regionId && this.props.regions) {
        //     currentRegion = this.props.regions.find(r => r.id == this.props.order.regionId)
        //     if (currentRegion && currentRegion.name) {
        //         currentRegion = currentRegion.name
        //     }
        // }

        let orderDate = moment(this.props.order.createdAt).format('DD.MM.YYYY HH:mm');
        let updateDate = moment(this.props.order.updatedAt).format('DD.MM.YYYY HH:mm');

        let productRequiredIngredients = [];
        let productAddablengredients = [];
        let productRemovableIngredients = [];

        let products = [];
        let middleSum = 0;
        Object.values(this.props.order.order_productvariants).map((opv, ind) => {
            productRequiredIngredients = [];
            productAddablengredients = [];
            productRemovableIngredients = [];

            let productsById = this.props.all_products;
            opv.discount_percent = opv.product_variant && opv.product_variant.productId && productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].discount_percent || 0;

            const prodComment = this.props.order.order_comment ? JSON.parse(this.props.order.order_comment).find(p => p.variantId === opv.productVariantId) : undefined;
            middleSum += parseInt(opv.quantity) * parseFloat(Utils.calculateProductPriceOrder(opv, this.props.settings, opv.opv_ingredients, 1, (this.props.order.ordertypeId == '2' || this.props.order.ordertypeId == '3')))

            const retProd = <p key={ind} style={{ padding: parseInt(Utils.getSettingsValue(this.props.settings, 'printer_padding_each_product')) }}>{opv.quantity} x <b>{opv.product_variant && opv.product_variant.product && opv.product_variant.product.name || ''}</b> - <i>{opv.product_variant && opv.product_variant.size || ''}</i> {((this.props.prodPrintGroups && this.props.prodPrintGroups.length == 0) || !this.props.prodPrintGroups) ? <>- /{Utils.calculateProductPriceOrder(opv, this.props.settings, opv.opv_ingredients, 1, (this.props.order.ordertypeId == '2' || this.props.order.ordertypeId == '3'))}{/*opv.product_variant && opv.product_variant.price || ''*/} {(this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value ? this.props.settings.default_currency_suffix.value : 'лв.')}/ </> : null}

                {((this.props.prodPrintGroups && this.props.prodPrintGroups.length == 0) || !this.props.prodPrintGroups) ?
                    <>
                        {(((this.props.order.ordertypeId == 2 || this.props.order.ordertypeId == 3) && (this.props.settings && this.props.settings.show_variant_additional_price && this.props.settings.show_variant_additional_price.value && this.props.settings.show_variant_additional_price.value == 1) && (opv.product_variant && (parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' с вкл. ' + (this.props.translations && this.props.translations.common && this.props.translations.common.box ? this.props.translations.common.box : 'кутия') + ' ' + opv.product_variant.additional_price + ' ' + (this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value ? this.props.settings.default_currency_suffix.value : 'лв.')) : '')}
                    </>
                    :
                    null
                }

                {prodComment && prodComment.comment ? <span> - <span style={{ fontStyle: "italic", fontWeight: 'bold', textDecoration: 'underline' }}>{prodComment.comment}</span></span> : null}
                {opv.opv_ingredients.map((ing, k) => {
                    if (ing.ingredient_type == "required") {
                        if (ing.ingredient) {
                            middleSum += parseFloat(ing.ingredient.price)
                            productRequiredIngredients.push(ing.ingredient.name + ' /' + ing.ingredient.price + ' ' + (this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value ? this.props.settings.default_currency_suffix.value : 'лв.') + '/')
                        }
                    }
                    if (ing.ingredient_type == "addable") {
                        if (ing.ingredient) {
                            middleSum += parseFloat(ing.ingredient.price)
                            productAddablengredients.push(ing.ingredient.name + ' /' + ing.ingredient.price + ' ' + (this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value ? this.props.settings.default_currency_suffix.value : 'лв.') + '/')
                        }
                    }
                    if (ing.ingredient_type == "removable") {
                        if (ing.ingredient) {
                            productRemovableIngredients.push(ing.ingredient.name);
                        }
                    }
                })}

                {
                    productRequiredIngredients.length > 0 ?
                        <div style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                            {productRequiredIngredients.map((ingredient, index) => (
                                <div key={index}>{ingredient}</div>
                            ))}
                        </div>
                        :
                        null
                }
                {
                    productAddablengredients.length > 0 ?
                        <div style={{ color: '#008017', fontStyle: 'italic', fontWeight: 'bold' }}>
                            {productAddablengredients.map((ingredient, index) => (
                                <div key={index}>+{ingredient}</div>
                            ))}
                        </div>
                        :
                        null
                }
                {
                    productRemovableIngredients.length > 0 ?
                        <div style={{ color: '#c50000', fontStyle: 'italic', fontWeight: 'bold' }}>
                            {productRemovableIngredients.map((ingredient, index) => (
                                <div key={index}>-{ingredient}</div>
                            ))}
                        </div>
                        :
                        null
                }
            </p>

            // products.push(retProd)

            // console.log('this.props.this.props.prodPrintGroups', this.props.this.props.prodPrintGroups);
            // console.log('this.props.prodPrintGroups', this.props.prodPrintGroups);
            if (
                // (this.props.this.props.prodPrintGroups && this.props.this.props.prodPrintGroups.length > 0) || 
                (this.props.prodPrintGroups && this.props.prodPrintGroups.length > 0)) {
                const currentProduct = Object.values(this.props.all_products).find(p => Object.values(p['variants']).find(v => v.id == opv.productVariantId));
                // console.log('currentProduct', currentProduct);
                // console.log('this.props.this.props.prodPrintGroups.includes(currentProduct.productPrintGroupId)', this.props.prodPrintGroups.includes(currentProduct.productPrintGroupId));
                if (this.props.prodPrintGroups.includes(currentProduct.productPrintGroupId)) {
                    products.push(retProd)
                    // return retProd
                } else {
                    // return null; // DO NOT PRINT PRODUCTS WHICH ARE IN OTHER PRINT GROUP
                }
            } else {
                products.push(retProd)
            }


        })

        return (
            <div class={"print-container pr" + (localStorage.getItem('tabl_printer_padding_right') ? localStorage.getItem('tabl_printer_padding_right') : '75')}>

                {/* {this.props.order.room_number && this.props.order.room_number != '' ?
                    null
                    : */}
                {Utils.getSettingsValue(this.props.settings, 'hide_restaurant_data_in_print_logo') == 1 ?
                    null
                    :
                    <div class="header-container">
                        <img src={Utils.getSettingsValue(this.props.settings, 'order_print_logo_url')} class="company-logo" />
                        <br /><br /><br />
                    </div>
                }
                {/* } */}

                <div>

                    <br />
                    <br />

                    <div class="talign-center">
                        {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                            <h3><b>Поръчка № {this.props.order.place_order_number ? this.props.order.place_order_number : this.props.order.id}</b></h3>
                            :
                            <h2><b>Поръчка № {this.props.order.place_order_number ? this.props.order.place_order_number : this.props.order.id}</b></h2>
                        }
                        <br />

                        {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                            <h3>
                                {this.props.order.ordertypeId == 6 ?
                                    'Час'
                                    :
                                    (this.props.order.ordertypeId == 2 ? 'Час на доставка' : 'Час на взимане от място')
                                }
                                <br />
                                <b><u>/{this.props.order.exact_time_selected ? orderDateFormat : 'възможно най-скоро'}/</u></b>
                                <br />
                                <b><i className={"badge badge-dark"}>{this.props.order.exact_time_selected && !Utils.checkDatesInSameDayAndHour(this.props.order.delivery_time || this.props.order.pickup_time, this.props.order.createdAt) ? 'БЪДЕЩА ПОРЪЧКА' : ''}</i></b>
                            </h3>
                            :
                            <h2>
                                {this.props.order.ordertypeId == 6 ?
                                    'Час'
                                    :
                                    (this.props.order.ordertypeId == 2 ? 'Час на доставка' : 'Час на взимане от място')
                                }
                                <br />
                                <b><u>/{this.props.order.exact_time_selected ? orderDateFormat : 'възможно най-скоро'}/</u></b>
                                <br />
                                <b><i className={"badge badge-dark"}>{this.props.order.exact_time_selected && !Utils.checkDatesInSameDayAndHour(this.props.order.delivery_time || this.props.order.pickup_time, this.props.order.createdAt) ? 'БЪДЕЩА ПОРЪЧКА' : ''}</i></b>
                            </h2>
                        }

                        {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                            <>
                                {((this.props.prodPrintGroups && this.props.prodPrintGroups.length == 0) || !this.props.prodPrintGroups) && this.props.order.room_number && this.props.order.additional_order_type ?
                                    <h3><b>Стая: {this.props.order.room_number} {this.props.order.additional_order_type == 'delivery' ? '- С ДОСТАВКА' : '- ВЗИМАНЕ ОТ МЯСТО'}</b></h3>
                                    :
                                    null
                                }
                            </>
                            :
                            <>
                                {((this.props.prodPrintGroups && this.props.prodPrintGroups.length == 0) || !this.props.prodPrintGroups) && this.props.order.room_number && this.props.order.additional_order_type ?
                                    <h2><b>Стая: {this.props.order.room_number} {this.props.order.additional_order_type == 'delivery' ? '- С ДОСТАВКА' : '- ВЗИМАНЕ ОТ МЯСТО'}</b></h2>
                                    :
                                    null
                                }
                            </>
                        }

                    </div>

                    <br /><br />
                    <br /><br />

                    {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                        <>

                            {(this.props.prodPrintGroups && this.props.prodPrintGroups.length == 0) || !this.props.prodPrintGroups ?
                                <div >
                                    <h2 class="talign-center">Данни на клиента:</h2>
                                    <div class="talign-left">
                                        <br />
                                        <h3>Име: <b style={{ wordBreak: 'break-word' }}>{this.props.order.client_name} </b></h3>
                                        <h3>Телефон: <b style={{ wordBreak: 'break-word' }}>{this.props.order.client_phone}</b></h3>
                                        {this.props.order && this.props.order.ordertypeId && this.props.order.ordertypeId == 1 ? <h3>Маса: <b>{this.props.order.table && this.props.order.table.table_num ? this.props.order.table.table_num : ''}</b></h3> : null}
                                        {this.props.order.ordertypeId == 2 ? <h3>Адрес: <b style={{ wordBreak: 'break-word' }}>{this.props.order.client_address} /

                                            {this.props.order.street_number ? ' № ' + this.props.order.street_number : null} {(this.props.order.ap_number ? ' Ап. ' + this.props.order.ap_number : '')} {(this.props.order.floor_number ? ' Ет. ' + this.props.order.floor_number : '')} {(this.props.order.bell_number ? ' Зв. ' + this.props.order.bell_number : '')} {(this.props.order.entrance_number ? ' Вх. ' + this.props.order.entrance_number : '')}
                                            {/* {this.props.order.street_number ? '/№ ' + this.props.order.street_number + '/' : null} */}

                                        </b></h3> : null}
                                    </div>

                                    {/* {currentRegion && this.props.order.ordertypeId == 2 ? <h2>Регион: <b>{currentRegion}</b></h2> : null} */}
                                    <br />
                                </div>
                                :
                                null
                            }
                        </>
                        :
                        <>
                            {(this.props.prodPrintGroups && this.props.prodPrintGroups.length == 0) || !this.props.prodPrintGroups ?
                                <div >
                                    <h1 class="talign-center">Данни на клиента:</h1>
                                    <div class="talign-left">

                                        <h2>Име: <b style={{ wordBreak: 'break-word' }}>{this.props.order.client_name} </b></h2>
                                        <h2>Телефон: <b style={{ wordBreak: 'break-word' }}>{this.props.order.client_phone}</b></h2>
                                        {this.props.order && this.props.order.ordertypeId && this.props.order.ordertypeId == 1 ? <h2>Маса: <b>{this.props.order.table && this.props.order.table.table_num ? this.props.order.table.table_num : ''}</b></h2> : null}
                                        {this.props.order.ordertypeId == 2 ? <h2>Адрес: <b style={{ wordBreak: 'break-word' }}>{this.props.order.client_address} /

                                            {this.props.order.street_number ? ' № ' + this.props.order.street_number : null} {(this.props.order.ap_number ? ' Ап. ' + this.props.order.ap_number : '')} {(this.props.order.floor_number ? ' Ет. ' + this.props.order.floor_number : '')} {(this.props.order.bell_number ? ' Зв. ' + this.props.order.bell_number : '')} {(this.props.order.entrance_number ? ' Вх. ' + this.props.order.entrance_number : '')}
                                            {/* {this.props.order.street_number ? '/№ ' + this.props.order.street_number + '/' : null} */}

                                        </b></h2> : null}

                                        {/* {currentRegion && this.props.order.ordertypeId == 2 ? <h2>Регион: <b>{currentRegion}</b></h2> : null} */}
                                        <br />
                                    </div>
                                </div>
                                :
                                null
                            }
                        </>
                    }
                    <br />
                    <div className="dash"></div>
                    <br />

                    {this.props.order.order_text_comment && this.props.order.order_text_comment.length > 0 ?
                        <>
                            <div class="talign-center">

                                {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                    <>
                                        <h2>Коментар към поръчката:</h2>
                                        <h3 style={{
                                            display: 'block',
                                            background: '#ccc',
                                            padding: 6,
                                            wordBreak: 'break-word'
                                        }}>
                                            {this.props.order.order_text_comment}
                                        </h3>
                                    </>
                                    :
                                    <>
                                        <h1>Коментар към поръчката:</h1>
                                        <h2 style={{
                                            display: 'block',
                                            background: '#ccc',
                                            padding: 6,
                                            wordBreak: 'break-word'
                                        }}>
                                            {this.props.order.order_text_comment}
                                        </h2>
                                    </>
                                }
                                <br />
                                <div className="dash"></div>
                                <br />
                            </div>
                        </>
                        :
                        null
                    }

                    {(this.props.prodPrintGroups && this.props.prodPrintGroups.length == 0) || !this.props.prodPrintGroups ?
                        <div class="talign-center">
                            {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                <h2>Данни на поръчката:</h2>
                                :
                                <h1>Данни на поръчката:</h1>
                            }
                            {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                <>
                                    <br />
                                    <h3 class="talign-left">{products}</h3>
                                </>
                                :
                                <h2 class="talign-left">{products}</h2>
                            }
                            <br />
                            <div className="dash"></div>
                            <br />
                            {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                <h2>Час на поръчка:</h2>
                                :
                                <h1>Час на поръчка:</h1>
                            }
                            {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                <h3><b>{orderDate}</b></h3>
                                :
                                <h2><b>{orderDate}</b></h2>
                            }
                            {this.props.order.ordertypeId == 2 ?
                                <>
                                    {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                        <h3>Доставка: <b>{delivery_price == 0 ? 'безплатна' : parseFloat(delivery_price).toFixed(2)}</b> {delivery_price == 0 ? null : 'лв.'}</h3>
                                        :
                                        <h2>Доставка: <b>{delivery_price == 0 ? 'безплатна' : parseFloat(delivery_price).toFixed(2)}</b> {delivery_price == 0 ? null : 'лв.'}</h2>
                                    }
                                </>
                                :
                                null
                            }
                            {Utils.getSettingsValue(this.props.settings, 'show_subtotal_sum_printer') == '1' ?
                                <React.Fragment>
                                    <br />
                                    {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                        <>
                                            <h2 style={{ display: 'inline-block' }}>Междинна сума: {middleSum.toFixed(2)} лв.</h2>
                                        </>
                                        :
                                        <>
                                            <h1 style={{ display: 'inline-block' }}>Междинна сума: {middleSum.toFixed(2)} лв.</h1>
                                        </>
                                    }
                                </React.Fragment>
                                :
                                null
                            }
                            {this.props.order.discount_price && this.props.order.discount_price != 0 ?
                                <React.Fragment>
                                    <br />
                                    {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                        <>
                                            <h2 style={{ display: 'inline-block', textDecoration: this.props.order.promocode && this.props.order.promocode.ignore_other_discounts ? 'line-through' : 'none' }}>Отстъпка: </h2>
                                            <h3 style={{ display: 'inline-block', textDecoration: this.props.order.promocode && this.props.order.promocode.ignore_other_discounts ? 'line-through' : 'none' }}>&nbsp;{this.props.order.discount_price}лв. {this.props.order.discount_type == 'percent' && this.props.order.discount_value ? <span>({this.props.order.discount_value}%)</span> : ''}</h3>
                                        </>
                                        :
                                        <>
                                            <h1 style={{ display: 'inline-block', textDecoration: this.props.order.promocode && this.props.order.promocode.ignore_other_discounts ? 'line-through' : 'none' }}>Отстъпка: </h1>
                                            <h2 style={{ display: 'inline-block', textDecoration: this.props.order.promocode && this.props.order.promocode.ignore_other_discounts ? 'line-through' : 'none' }}>&nbsp;{this.props.order.discount_price}лв. {this.props.order.discount_type == 'percent' && this.props.order.discount_value ? <span>({this.props.order.discount_value}%)</span> : ''}</h2>
                                        </>
                                    }
                                </React.Fragment>
                                :
                                null
                            }
                            {this.props.order.user_discount_value && this.props.order.user_discount_type && this.props.order.user_discount_price ?
                                <React.Fragment>
                                    <br />
                                    {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                        <>
                                            <h2>Лична отстъпка: </h2>
                                            <h3>&nbsp;{this.props.order.user_discount_value} {this.props.order.user_discount_type == 'percent' ? '%' : this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value || (this.props && this.props.translations && this.props.translations.common && this.props.translations.common.currency ? this.props.translations.common.currency : '')} <span>({this.props.order.user_discount_price} {this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value || (this.props && this.props.translations && this.props.translations.common && this.props.translations.common.currency ? this.props.translations.common.currency : '')})</span></h3>
                                        </>
                                        :
                                        <>
                                            <h1>Лична отстъпка: </h1>
                                            <h2>&nbsp;{this.props.order.user_discount_value} {this.props.order.user_discount_type == 'percent' ? '%' : this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value || (this.props && this.props.translations && this.props.translations.common && this.props.translations.common.currency ? this.props.translations.common.currency : '')} <span>({this.props.order.user_discount_price} {this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value || (this.props && this.props.translations && this.props.translations.common && this.props.translations.common.currency ? this.props.translations.common.currency : '')})</span></h2>
                                        </>
                                    }
                                </React.Fragment>
                                :
                                null
                            }
                            {this.props.order.promocode && this.props.order.promocode.discount_value && this.props.order.promocode.discount_type ?
                                <React.Fragment>
                                    <br />
                                    {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                        <>
                                            <h2 style={{ display: 'inline-block' }}>Отстъпка Промокод: </h2>
                                            <h3 style={{ display: 'inline-block' }}>&nbsp;{this.props.order.promocode.discount_value} {this.props.order.promocode.discount_type == 'percent' ? '%' : (this.props && this.props.translations && this.props.translations.common && this.props.translations.common.currency ? this.props.translations.common.currency : '')}</h3>
                                        </>
                                        :
                                        <>
                                            <h1 style={{ display: 'inline-block' }}>Отстъпка Промокод: </h1>
                                            <h2 style={{ display: 'inline-block' }}>&nbsp;{this.props.order.promocode.discount_value} {this.props.order.promocode.discount_type == 'percent' ? '%' : (this.props && this.props.translations && this.props.translations.common && this.props.translations.common.currency ? this.props.translations.common.currency : '')}</h2>
                                        </>
                                    }
                                </React.Fragment>
                                :
                                null
                            }

                            {Utils.getSettingsValue(this.props.settings, 'hide_print_order_total_sum') == 1 ?
                                null :
                                <>
                                    {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                        <h3>Сметка: <b>{totalSum}</b> лв.</h3>
                                        :
                                        <h2>Сметка: <b>{totalSum}</b> лв.</h2>
                                    }
                                </>
                            }

                            <div>

                                {Utils.hasFunctionalityModule("payments") ?
                                    this.props.order.payment_status == PAYMENT_STATUSES.PAID ?
                                        <>
                                            {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                                <h3 style={{ display: 'inline-block' }}><b>{'Платена с EPAY'}</b></h3>
                                                :
                                                <h2 style={{ display: 'inline-block' }}><b>{'Платена с EPAY'}</b></h2>
                                            }
                                        </>
                                        :
                                        null
                                    :
                                    null
                                }

                                {this.props.order.payment_method ? <h2 style={{ display: 'inline-block' }}><b>{this.props.order.payment_method == 'cash' ? 'В брой' : (this.props.order.payment_method == 'card' ? 'С карта' : (this.props.order.payment_method == 'on_room_bill' ? 'На сметката на стаята' : null))}</b></h2> : ''}

                                <span>
                                    {(Utils.getSettingsValue(this.props.settings, 'show_cutlery_option_on_making_delivery_order') == '1' && this.props.order.ordertype.type == "delivery") ||
                                        (Utils.getSettingsValue(this.props.settings, 'show_cutlery_option_on_making_preorder_order') == '1' && this.props.order.ordertype.type == "preorder") ||
                                        (Utils.getSettingsValue(this.props.settings, 'show_cutlery_option_on_making_room_order') == '1' && this.props.order.ordertype.type == "room") ?
                                        <span>
                                            {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                                <>
                                                    <h3 style={{ display: 'inline-block' }}>&nbsp; / &nbsp;</h3>
                                                    <h3 style={{ display: 'inline-block' }}><b>
                                                        {/* {this.props.order.cutlery == '0' ? 'Без прибори' : 'С прибори'} */}

                                                        {this.props.order.cutlery == '1'
                                                            ||
                                                            (((this.props.order.ordertype.type == "delivery" || this.props.order.ordertype.type == "preorder")) && ((this.props.order.ordertype.type == "delivery" || this.props.order.ordertype.type == "preorder") && this.props.order.count_cutlery && parseInt(this.props.order.count_cutlery) > 0))
                                                            ?
                                                            (
                                                                'С прибори ' + (this.props.order.count_cutlery > 0
                                                                    ? parseFloat(this.props.order.count_cutlery) + ' бр. - ' + (Utils.getSettingsValue(this.props.settings, 'additional_price_each_cutlery') && Utils.getSettingsValue(this.props.settings, 'additional_price_each_cutlery') != '0' ? (parseFloat(Utils.getSettingsValue(this.props.settings, 'additional_price_each_cutlery')) * parseFloat(this.props.order.count_cutlery)).toFixed(2) + ' лв.' : '')
                                                                    : '')
                                                            )
                                                            : (this.props.order.cutlery == '0' ? 'Без прибори' : null)
                                                        }

                                                    </b></h3>
                                                </>
                                                :
                                                <>
                                                    <h2 style={{ display: 'inline-block' }}>&nbsp; / &nbsp;</h2>
                                                    <h2 style={{ display: 'inline-block' }}><b>
                                                        {/* {this.props.order.cutlery == '0' ? 'Без прибори' : 'С прибори'} */}

                                                        {this.props.order.cutlery == '1'
                                                            ||
                                                            (((this.props.order.ordertype.type == "delivery" || this.props.order.ordertype.type == "preorder")) && ((this.props.order.ordertype.type == "delivery" || this.props.order.ordertype.type == "preorder") && this.props.order.count_cutlery && parseInt(this.props.order.count_cutlery) > 0))
                                                            ?
                                                            (
                                                                'С прибори ' + (this.props.order.count_cutlery > 0
                                                                    ? parseFloat(this.props.order.count_cutlery) + ' бр. - ' + (Utils.getSettingsValue(this.props.settings, 'additional_price_each_cutlery') && Utils.getSettingsValue(this.props.settings, 'additional_price_each_cutlery') != '0' ? (parseFloat(Utils.getSettingsValue(this.props.settings, 'additional_price_each_cutlery')) * parseFloat(this.props.order.count_cutlery)).toFixed(2) + ' лв.' : '')
                                                                    : '')
                                                            )
                                                            : (this.props.order.cutlery == '0' ? 'Без прибори' : null)
                                                        }

                                                    </b></h2>
                                                </>
                                            }

                                        </span>
                                        :
                                        null
                                    }
                                </span>

                                {this.props.order.isClientNew ? <>
                                    {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                        <h3 style={{ display: 'inline-block' }} className={"badge badge-dark"}>Нов клиент</h3>
                                        :
                                        <h2 style={{ display: 'inline-block' }} className={"badge badge-dark"}>Нов клиент</h2>
                                    }
                                </> : null}

                            </div>
                            <br />
                        </div>
                        :
                        <>
                            {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                <div>
                                    <h2 class="tera talign-left">{products}</h2>
                                    <div class="talign-center">
                                        <h2>Час на поръчка:</h2>
                                        <h3><b>{orderDate}</b></h3>
                                    </div>
                                </div>
                                :
                                <div>
                                    <h1 class="tera talign-left">{products}</h1>
                                    <div class="talign-center">
                                        <h1>Час на поръчка:</h1>
                                        <h2><b>{orderDate}</b></h2>
                                    </div>
                                </div>
                            }
                        </>
                    }

                    <br />
                    <div className="dash"></div>
                    <br />
                </div>


                {((this.props.prodPrintGroups && this.props.prodPrintGroups.length == 0) || !this.props.prodPrintGroups) && Utils.getSettingsValue(this.props.settings, 'additional_text_in_printer_receipt').length > 0 ?
                    <>
                        <br />
                        {/* {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                            <h4 style={{ textAlign: 'center', fontStyle: 'italic', textDecoration: 'underline' }}>{Utils.getSettingsValue(this.props.settings, 'additional_text_in_printer_receipt')}</h4>
                            : */}
                        <h3 style={{ textAlign: 'center', fontStyle: 'italic', textDecoration: 'underline' }}>{Utils.getSettingsValue(this.props.settings, 'additional_text_in_printer_receipt')}</h3>
                        {/* } */}
                        <br />
                    </>
                    :
                    null
                }

                {((this.props.prodPrintGroups && this.props.prodPrintGroups.length == 0) || !this.props.prodPrintGroups) ?
                    <div class="talign-center middle-container">
                        {this.props.order.additional_order_type ?
                            null :
                            (this.props.order.room_number && this.props.order.room_number != '') ?
                                <>
                                    {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                        <h3>Стая: {this.props.order.room_number}</h3>
                                        :
                                        <h2>Стая: {this.props.order.room_number}</h2>
                                    }
                                </>
                                :
                                <>
                                    {/* {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                        <h4>Поръчай за вкъщи от тук:</h4>
                                        : */}
                                    <h3>Поръчай за вкъщи от тук:</h3>
                                    {/* } */}
                                </>
                        }

                        {this.props.order.room_number && this.props.order.room_number != '' ?
                            null
                            :
                            <React.Fragment>
                                {/* {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                    <h4>{Utils.getSettingsValue(this.props.settings, 'order_print_menu_url')}</h4>
                                    : */}
                                <h3>{Utils.getSettingsValue(this.props.settings, 'order_print_menu_url')}</h3>
                                {/* } */}
                                <br />
                                <br />


                                {Utils.getSettingsValue(this.props.settings, 'show_courier_qr_code_in_order_mark') == 1 ?
                                    <>
                                        {this.props.order.ordertype.type == 'delivery' ?
                                            <>
                                                <h4>QR кода за куриера:</h4>
                                                <QRCode
                                                    id="qr-code-courier-system"
                                                    value={"https://menu.tabl.bg/admin/couriers/" + this.props.order.uuid}
                                                    size={800}
                                                    level={"L"}
                                                    includeMargin={true}
                                                // style={{display: 'none'}}
                                                />
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    Utils.getSettingsValue(this.props.settings, 'order_print_qr_code_for_navigation') == 1 ?
                                        <>
                                            {this.props.order.ordertype.type == 'delivery' ?
                                                <>
                                                    {/* {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                                    <h4>QR кода до адреса:</h4>
                                                    : */}
                                                    <h3>QR кода до адреса:</h3>
                                                    {/* // } */}
                                                    <br />
                                                    <QRCode
                                                        id="qr-code-navigate"
                                                        value={"https://www.google.com/maps/dir/?api=1&destination=" +
                                                            (this.props.order.delivery_address_lat && this.props.order.delivery_address_lng ? (this.props.order.delivery_address_lat + ',' + this.props.order.delivery_address_lng) :
                                                                (this.props.order.client_address && this.props.order.client_address.split("/")[0] + '' + (this.props.order.street_number ? ' № ' + this.props.order.street_number : '')))
                                                            // (this.props.order && this.props.order.client_address && this.props.order.client_address.indexOf('/') !== -1 && this.props.order.client_address.split("/")[0] ? this.props.order.client_address.split("/")[0] : '')
                                                        }
                                                        size={800}
                                                        level={"L"}
                                                        includeMargin={true}
                                                    // style={{display: 'none'}}
                                                    />
                                                </>
                                                :
                                                null
                                            }
                                        </> :
                                        <>
                                            {/* {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                            <h4>Или сканирай QR кода:</h4>
                                            : */}
                                            <h3>{Utils.getSettingsValue(this.props.settings, 'printer_text_scan_qr_code')}</h3>
                                            {/* // } */}
                                            <br />

                                            <img src={Utils.getSettingsValue(this.props.settings, 'order_print_qr_code_url')} class="qr-code-logo"
                                                style={{ width: Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') == 1 ? '70%' : '100%' }}
                                            />
                                        </>
                                }


                                {Utils.getSettingsValue(this.props.settings, 'use_google_location_coordinates') == 1 && Utils.getSettingsValue(this.props.settings, 'order_print_qr_code_for_navigation') != 1 ?
                                    <>
                                        {this.props.order.ordertype.type == 'delivery' ?
                                            <>
                                                {/* {Utils.getSettingsValue(this.props.settings, 'printer_settings_use_smaller_font_size') ?
                                                    <h4>QR кода до адреса:</h4>
                                                    : */}
                                                <h3>QR кода до адреса:</h3>
                                                {/* // } */}
                                                <br />
                                                <QRCode
                                                    id="qr-code-navigate"
                                                    value={"https://www.google.com/maps/dir/?api=1&destination=" +
                                                        (this.props.order.delivery_address_lat && this.props.order.delivery_address_lng ? (this.props.order.delivery_address_lat + ',' + this.props.order.delivery_address_lng) :
                                                            (this.props.order.client_address && this.props.order.client_address.split("/")[0] + '' + (this.props.order.street_number ? ' № ' + this.props.order.street_number : '')))
                                                        // (this.props.order && this.props.order.client_address && this.props.order.client_address.indexOf('/') !== -1 && this.props.order.client_address.split("/")[0] ? this.props.order.client_address.split("/")[0] : '')
                                                    }
                                                    size={800}
                                                    level={"L"}
                                                    includeMargin={true}
                                                // style={{display: 'none'}}
                                                />
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }


                            </React.Fragment>
                        }
                    </div>
                    :
                    null
                }
                {/* 
                <br />
                <br />
                <hr />
                <br />
                <br />

                <div class="footer-container">
                    <img src="https://tabl.bg/img/tabl-transparent.png" class="tabl-logo" />

                    <div>
                        <h2>TABL.BG - Дигитално меню и система за поръчки</h2>
                    </div>

                </div> */}
            </div >

        );
    }
}
