import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import Auth from '../../utils/Auth';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../constants.js';
import { addProduct, bulkAddProducts } from '../../actions/productsActions';
import * as XLSX from 'xlsx';

class BulkAddProductsV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputText: '',
            categories: {},
            parsedProducts: [],
            selectedCategoryIds: {},
            file: null
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleParse = this.handleParse.bind(this);
    }

    handleInputChange(event) {
        this.setState({ inputText: event.target.value });
    }

    handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ file }, this.handleParseFile);
        }
    }

    handleParseFile = () => {
        const { file } = this.state;
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            let products = [];
            jsonData.slice(1).forEach(row => {
                if (row.length >= 3) {
                    const [name, price, priority] = row.slice(1, 4);
                    if (name && price) {
                        products.push({ name, price: parseFloat(price), priority: priority ? parseInt(priority) : 999 });
                    }
                }
            });

            products.sort((a, b) => a.priority - b.priority);
            this.setState({ parsedProducts: products });
        };

        reader.readAsArrayBuffer(file);
    }


    handleParse() {
        const { inputText } = this.state;
        const products = this.parseInputText(inputText);

        this.setState({ parsedProducts: products });

        // Pseudo code for inserting products into the database
        // products.forEach(product => {
        //     // Database insertion logic here
        //     console.log('Inserting product into database:', product);
        // });
    }

    handleInsert = () => {
        const { parsedProducts, selectedCategoryIds } = this.state;
        let arrToInsert = []

        let dataToSend = new FormData()

        let index = 0
        parsedProducts.forEach(product => {
            const categoryId = selectedCategoryIds[product.category];
            if (categoryId) {
                dataToSend.append("product_" + index, JSON.stringify({
                    category: [{ value: categoryId, label: product.category }],
                    // categoryId,
                    name: product.name,
                    price: product.price,
                    size: ' ',
                    catHierarchyIds: [categoryId]
                }));
                arrToInsert.push({
                    category: [{ value: categoryId, label: product.category }],
                    // categoryId,
                    name: product.name,
                    price: product.price,
                    size: ' '
                })
                // console.log('Inserting product:', {
                //     category: { value: categoryId, label: product.category },
                //     // categoryId,
                //     name: product.name,
                //     price: product.price,
                //     size: ' '
                // });
                // Insert product into the database with categoryId
                // Your database insertion logic here
            } else {
                console.log('No category ID selected for category:', product.category);
            }
            index++;
        });

        console.log('arrToInsert', arrToInsert);
        this.props.bulkAddProducts(dataToSend, this.props.history);

    }

    handleCategoryChange = (category, event) => {
        const { selectedCategoryIds } = this.state;
        selectedCategoryIds[category] = event.target.value;
        this.setState({ selectedCategoryIds });
    }

    parseInputText(text) {
        const lines = text.split('\n');
        let currentCategory = '';
        const products = [];
        let currentProductLines = [];

        lines.forEach(line => {
            line = line.trim();
            if (line && line.length > 0) {
                // console.log('line', line);
                if (line.endsWith(':')) {
                    if (currentProductLines.length > 0) {
                        this.addProduct(products, currentCategory, currentProductLines.join(' '));
                        currentProductLines = [];
                    }
                    currentCategory = line.slice(0, -1);
                } else if (line && ((line.startsWith('/') && line.indexOf('лв') != -1) || (!line.startsWith('/')))) {
                    currentProductLines.push(line);
                    console.log('pushing product line', line);
                    if (line.indexOf('лв') != -1) { // If 'лв' found
                        this.addProduct(products, currentCategory, currentProductLines.join(' '));
                        currentProductLines = [];
                        console.log('inserting -- ');
                    } else {
                        console.log('ЛВ not found in line:', JSON.stringify(currentProductLines));
                    }
                }
            }
        });

        if (currentProductLines.length > 0) {
            this.addProduct(products, currentCategory, currentProductLines.join(' '));
        }

        console.log('products Before parse', products);
        return products;
    }

    addProduct(products, category, productLine) {
        const parts = productLine.split(/(?:\s|-|–| -| - |– | – |-\s|–\s)(?=\d+[,.]?\d*\s?лв)/);
        if (parts.length === 2) {
            const productName = parts[0].trim();
            const priceMatch = parts[1].match(/(\d+[,.]?\d*)\s?лв/);
            if (priceMatch) {
                let price = priceMatch[1].replace(',', '.'); // Convert , to . for decimal parsing
                price = parseFloat(price); // Convert to number
                products.push({
                    category: category,
                    name: productName,
                    price: price
                });
            } else {
                console.log('No price found for:', productName);
            }
        } else {
            console.log('Check parsing for:', productLine);
        }
    }
    
    render() {
        const { categories, inputText, parsedProducts, selectedCategoryIds } = this.state;

        // Calculate the count of products for each category
        const categoryCounts = parsedProducts.reduce((acc, product) => {
            acc[product.category] = (acc[product.category] || 0) + 1;
            return acc;
        }, {});

        return (
            <main className="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink>
                    </li>
                    <li className="breadcrumb-item active">{this.props.translations.categories.title}</li>
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `categories-hierarchy/add`} className="btn">
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.categories.add}
                            </NavLink>
                        </div>
                    </li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-12">
                                <h5>Upload File or Enter Products Manually:</h5>
                                <input type="file" className="form-control mb-3" accept=".xlsx" onChange={this.handleFileUpload} />

                                <textarea
                                    className="form-control"
                                    value={inputText}
                                    onChange={this.handleInputChange}
                                    rows="10"
                                    placeholder="Paste menu text here..."
                                />
                                <button
                                    className="btn btn-primary mt-3"
                                    onClick={this.handleParse}
                                >
                                    Parse Products
                                </button>
                                <div className="mt-4">
                                    <h5>Parsed Products:</h5>
                                    <ul>
                                        {parsedProducts.map((product, index) => (
                                            <li key={index}>
                                                <strong>{product.category}:</strong> {product.name} - {product.price}лв
                                            </li>
                                        ))}
                                    </ul>
                                    <h5>Product Counts by Category:</h5>
                                    <ul>
                                        {Object.entries(categoryCounts).map(([category, count], index) => (
                                            <li key={index} style={{ margin: '10px' }}>
                                                <strong>{category}:</strong> {count} products
                                                <select
                                                    value={selectedCategoryIds[category] || ''}
                                                    onChange={(e) => this.handleCategoryChange(category, e)}
                                                >
                                                    <option value="">Select Category</option>
                                                    {Object.values(this.props.cats).map(c => (
                                                        <option key={c.id} value={c.id}>{c.name}</option>
                                                    ))}
                                                </select>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button
                                    className="btn btn-primary mt-3"
                                    onClick={this.handleInsert}
                                >
                                    Import new Products and Delete old Products
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log('state', state);
    return {
        cats: state.categoriesHierarchy.categories || [],
        translations: state.lang,
        settings: state.settings.settings,
        places: state.places.places,
        clients: state.clients.clients,
    };
};

export default connect(mapStateToProps, { bulkAddProducts })(BulkAddProductsV2);
